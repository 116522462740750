import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {LiteYouTubeEmbed} from 'react-lite-youtube-embed';

import Layout from '../components/layout';
import SEO from '../components/seo';
import '../../App.css';
require('typeface-rubik');

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const OutlineButton = styled.button`
  height: 42px;
  width: 100px;
  background-color: #FFFFFF;
  color: #5E72E4;
  border-color: #5E72E4;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

const price = {
    fontSize: '100px',
    paddingBottom: '35px'
};

const Point = styled.h2`
  font-weight: 500;
  font-size: 25px;
  margin-top: 0px;
`;

const highlight = {
    background: '#c9cbff',
    color: '#000000',
    textDecoration: 'underline'
};


const IndexPage = () => {
    const [screenWidth, setScreenWidth] = useState('50%');
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth < 480) {
            setScreenWidth('100%');
        }
    }, []);

    return (
        <Layout >
            <SEO
                title={'AirPlug | Airtable Offline Mode'}
                description={'Airtable mobile app for offline sync. Instant access to all your tables in offline mode.'}
                url={'https://airplug.xyz'}
            />
            <Container style={{backgroundColor: '#f5f9fb'}}>
                <Banner windowWidth={windowWidth}/>
            </Container>

            <Container>
                <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                        Airtable isn't fun in the offline mode. 🙁
                </div>
            </Container>

            <div style={{textAlign: 'center'}}>
                <Container>
                    <picture>
                        <source
                            style={{
                                width: '45%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            type='image/avif'
                            alt='airtable-offline-connect'
                            srcSet={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fairtable-offline-connect.avif?alt=media&token=499d64f8-387b-40e5-a810-e0944be16d9d'}
                        />
                        <img
                            style={{
                                width: '45%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            alt='airtable-offline-connect'
                            src={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fairtable-offline-connect.jpg?alt=media&token=9637322b-355d-4bdd-8a7a-afd189a31428'}
                        />
                    </picture>
                </Container>
            </div>

            <Container>
                <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                      With AirPlug, you never lose access to your Airtable data 🙂
                </div>
            </Container>

            <div style={{textAlign: 'center'}}>
                <Container>
                    <picture>
                        <source
                            style={{
                                width: '90%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            type='image/avif'
                            alt='airtable-offline-mobile'
                            srcSet={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fairtable-offline-mode.avif?alt=media&token=0e5ad595-49fd-4f17-ba48-c9b97b936ee5'}
                        />
                        <img
                            style={{
                                width: '90%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            alt='airtable-offline-mobile'
                            src={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fairtable-offline-mode.jpg?alt=media&token=bd1570d7-675d-436a-bfdd-60cf041774b5'}
                        />
                    </picture>
                </Container>
            </div>


            <Container style={{margin: '0 auto', width: screenWidth, backgroundColor: '#f5f9fb'}}>
                <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                    We got all your offline needs covered.
                </div>
                <br/>
                <ul className='a'>
                    <li><Point>View Airtable records for offline availability</Point></li>
                    <li><Point>View multimedia like images in offline mode as well</Point></li>
                    <li><Point>Fetch new data as soon you online again</Point></li>
                    <li><Point>Works for Android</Point></li>
                </ul>
            </Container>

            <Container style={{margin: '0 auto', width: screenWidth}}>
                <div style={{fontSize: '45px', textAlign: 'center', lineHeight: '1.2', fontWeight: '600'}}>
                    Checkout the demo now
                </div>
                <div className='card'>
                    <LiteYouTubeEmbed
                        id='Zl5j4_kUN9M'
                        title='AirPlug -  Airtable Offline Mode'
                        style={{width: '100%', height: 290}}
                        allowFullScreen={true}
                    />
                    <Container>
                        <h2 style={{fontWeight: '500', fontSize: '20px'}}>
                                Airplug Demo - Airtable Offline Mode
                        </h2>
                    </Container>
                </div>
            </Container>

            <Container style={{margin: '0 auto', width: screenWidth}}>
                <h2 style={{fontSize: '35px', textAlign: 'center', lineHeight: '1.2', fontWeight: '600'}}>
                    Just enter your <a style={highlight} onClick={() => window.open('https://airtable.com/api')}>table's API details</a> and install the app from the play store
                </h2>


                <a onClick={() => window.open('https://play.google.com/store/apps/details?id=com.trakbit.airplug')}>
                    <picture>
                        <source
                            style={{width: '200px'}}
                            alt='airtable-offline-playstore'
                            type='image/avif'
                            srcSet={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fplaystore.avif?alt=media&token=6927af51-5299-411b-91ba-b42d086c06bf'}
                        />
                        <img
                            style={{width: '200px'}}
                            alt='airtable-offline-playstore'
                            src={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fplaystore.jpg?alt=media&token=95e3faf5-5fe7-46f0-a4ca-7cedff82d3dc'}
                        />
                    </picture>
                </a>

            </Container>

            <Container style={{margin: '0 auto', width: screenWidth}}>
                <div style={{fontSize: '45px', textAlign: 'center', lineHeight: '1.2', fontWeight: '600'}}>
                    Interested? <a style={highlight} onClick={() => window.open('https://twitter.com/harsh_vardhhan')}>Contact me </a> now to activate your subscription or a custom solution
                </div>
            </Container>

            <Container>
                <div style={{textAlign: 'center', borderRadius: '30px', width: screenWidth, marginLeft: '5%'}}>
                    <iframe className="airtable-embed" src="https://airtable.com/embed/shrdzoDkifdKLfvkR?backgroundColor=orange" frameBorder="0" width="100%" height="533" 
                    style={{
                        background: 'transparent',
                        border: ' 1px solid #ccc',
                        textAlign: 'center'
                    }}></iframe>
                </div>
            </Container>

            <div style={{textAlign: 'center', backgroundColor: '#f5f9fb', borderRadius: '30px', width: '90%', marginLeft: '5%'}}>
                <br/>
                <div style={heading}>{'Subscription'}</div>
                <div style={{marginTop: '30px', marginBottom: '5%'}}>
                    <br/>
                    <div style={price}>
                        $19
                    </div>
                    <div style={heading}>{'user / month'}</div>
                    <br/>
                </div>
            </div>



        </Layout>
    );
};

const Banner = ({windowWidth}) => {
    if (windowWidth > 480) {
        return (
            <>
                <h1 style={{textAlign: 'center', fontSize: '5vw', lineHeight: '1.5', fontWeight: '600'}}>
                    Airtable Offline Mode
                </h1>
            </>
        );
    } else {
        return (
            <>
                <h1 style={{fontSize: '9vw', textAlign: 'center', lineHeight: '1.3'}}>
                    Airtable Offline Mode
                </h1>
            </>
        );
    }
};

export default IndexPage;
